.lesson-description code {
  color: #3b3b3b;
  background: #fafafa;
  padding: 1px 8px;
  font-size: 90%;
  border-radius: 5px;
}

pre {
  background: #fafafa !important;
  margin-top: 10px !important;
}
