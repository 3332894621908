.ReactModal__Body--open {
  overflow: hidden;
}

.content-buttom-message-to-comment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courses-popup-wrap {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #404040;
  font-size: 20px;
  font-weight: 700;
}

.close-modal-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.close-modal-button:after {
  content: "×";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background 0.25s;
  box-sizing: border-box;
  z-index: 2;
}

.modal-popup {
  margin: 44px 0;
}

.send-comment {
  cursor: pointer;
  border-bottom: 1px dashed #bcbcbc;
  color: #bcbcbc;
  font-size: 18px;
  float: left;
  margin-top: 5px;
  -webkit-transition: all 200ms;
}

.buttonModalReviewStepTask:hover {
  cursor: pointer;
  background-color: rgba(200, 200, 200, 0.5);
  color: rgba(60, 60, 60, 0.99);
}

.buttonModalReviewStepTask {
  height: 25px;
  width: 380px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: center;
  text-align: center;
  color: rgba(74, 74, 74, 0.99);
  background-color: rgba(188, 188, 188, 0.5);
  text-decoration: underline;
  margin: 15px auto 0;
  border-radius: 50px;
}
