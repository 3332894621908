.header .top-header {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(228, 228, 228, 0.4);
  height: 64px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: 10;
}

.header .top-header .logo {
  float: left;
  display: inline-block;
  margin-top: 17px;
  margin-right: 64px;
}

.header .top-header .top-nav {
  float: left;
  padding: 0px;
  list-style: none;
}

.header .top-header .header-mentor-panel {
  padding:  15px 30px  0 10px ;
  font-size: 14px;
}

.header .top-header .header-mentor-panel select {
  border: #bfbfbf 1px solid;
  border-radius: 3px;
}

.header .top-header .top-nav li {
  float: left;
}

.header .top-header .top-nav li a {
  border-bottom: 3px solid #ffffff;
  color: #404040;
  font-size: 18px;
  display: block;
  padding: 0px 20px;
  padding-top: 17px;
  height: 64px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.header .top-header .top-nav li a:hover {
  border-bottom: 3px solid #c0d6ff;
}

.header .top-header .top-nav li.active a {
  color: #4B8AFF;
  border-bottom: 3px solid #4B8AFF;
}

.header .top-header .header-right-wrap {
  float: right;
  position: relative;
}

.header .top-header .header-right-wrap .notify-wrap {
  float: left;
  position: relative;
}

.header .top-header .header-right-wrap .notify-btn {
  padding: 0px 6px;
  height: 64px;
  width: 52px;
  line-height: 1;
  padding-top: 11px;
  position: relative;
}

.header .top-header .header-right-wrap .notify-btn i {
  color: #4B8AFF;
  font-size: 38px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.header .top-header .header-right-wrap .notify-btn .notify-counter {
  background: #fff;
  border: 1px solid #4B8AFF;
  border-radius: 50%;
  position: absolute;
  color: #4B8AFF;
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  width: 20px;
  right: 6px;
  top: 30px;
  text-align: center;
  line-height: 18px;
}

.header .top-header .header-right-wrap .notify-btn:hover {
  cursor: pointer;
}

.header .top-header .header-right-wrap .notify-btn:hover i {
  color: #3b80ff;
}

.header .top-header .header-right-wrap .notify-message-wrap {
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  border-radius: 4px;
  padding: 28px 22px;
  position: absolute;
  right: -6px;
  top: 70px;
  width: 452px;
  z-index: 8;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message-head {
  color: #404040;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message {
  border-bottom: 1px solid #E8E6EF;
  display: block;
  margin-bottom: 14px;
  padding-bottom: 14px;
  position: relative;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-message-pic {
  background: #8db5ff;
  border-radius: 50%;
  float: left;
  margin-right: 12px;
  margin-top: 3px;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-message-pic i {
  color: #fff;
  font-size: 24px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-text-wrap .message-title {
  color: #404040;
  font-size: 16px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message .notify-text-wrap .message-date {
  color: #D2D2D2;
  font-size: 16px;
}

.header .top-header .header-right-wrap .notify-message-wrap .notify-message:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.header .top-header .header-right-wrap .notify-message-wrap:before {
  content: "";
  display: block;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  position: absolute;
  top: -10px;
  right: 22px;
}

.header .top-header .header-right-wrap .notify-message-wrap:after {
  border-radius: 0px 4px 0px 0px;
  content: "";
  display: block;
  background: #fff;
  width: 64px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.header .top-header .header-right-wrap .profile-menu-wrap {
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  border-radius: 4px;
  padding: 6px 0px;
  padding-bottom: 4px;
  position: absolute;
  right: -8px;
  top: 70px;
  width: 254px;
  z-index: 8;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li a {
  color: #404040;
  font-size: 16px;
  display: block;
  padding: 14px 22px;
  position: relative;
  z-index: 10;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li a:hover {
  background: #e8f1ff;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li:first-child a {
  border-bottom: 1px solid #E8E6EF;
  padding-bottom: 14px;
}

.header .top-header .header-right-wrap .profile-menu-wrap ul li:last-child a {
  border-top: 1px solid #E8E6EF;
  padding-top: 14px;
}

.header .top-header .header-right-wrap .profile-menu-wrap:before {
  content: "";
  display: block;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  box-shadow: 0 0 10px 0 rgba(116, 116, 116, 0.12);
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  position: absolute;
  top: -10px;
  right: 17px;
}

.header .top-header .header-right-wrap .profile-menu-wrap:after {
  border-radius: 0px 4px 0px 0px;
  content: "";
  display: block;
  background: #fff;
  width: 42px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 0px;
}

.header .top-header .header-right-wrap .profile-icn {
  border: 2px solid #4B8AFF;
  border-radius: 50%;
  float: left;
  height: 42px;
  width: 42px;
  margin-top: 10px;
  margin-left: 20px;
  overflow: hidden;
}

.header .top-header .header-right-wrap .profile-icn img {
  /*width: 100%;*/
  object-fit: cover;
  object-position: 50% 50%;
}

.header .top-header .header-right-wrap .profile-icn:hover {
  cursor: pointer;
}

.header .top-header .header-right-wrap .mobile-menu-btn {
  display: none;
}

.header .middle-header {
  background: #fff;
  margin-top: 64px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.header .middle-header .page-title-course-pic {
  border: 1px solid #E8E8E8;
  border-radius: 9px;
  float: left;
  width: 132px;
  height: 82px;
  margin-right: 25px;
  overflow: hidden;
}

.header .middle-header .page-title-course-pic img {
  object-fit: scale-down;
  /*max-width: 130px;*/
  /*max-height: 82px;*/
  height: 100%;
  width: 100%;
}

.header .middle-header .page-title-wrap .breadcrumbs .link a {
  color: #C3C3C3;
  font-size: 20px;
}

.header .middle-header .page-title-wrap .breadcrumbs .link a:hover {
  text-decoration: underline;
}

.header .middle-header .page-title-wrap .breadcrumbs .link:after {
  color: #C3C3C3;
  content: "/";
  font-size: 20px;
  margin: 0px 8px;
  position: relative;
  top: 1px;
}

.header .middle-header .page-title-wrap .page-title {
  color: #404040;
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
}

.header .middle-header.lesson-step .page-title-course-pic {
  height: 142px;
  width: 224px;
}

.header .middle-header.lesson-step .page-title-course-pic img {
  max-height: 142px;
  max-width: 224px;
}

.header .middle-header.lesson-step .page-title-wrap {
  overflow: hidden;
}

.header .middle-header.lesson-step .page-title-wrap .page-title {
  display: inline-block;
  margin-right: 25px;
}

.header .middle-header.lesson-step .page-title-wrap .page-title-progress {
  color: #8A8A8A;
  font-size: 20px;
  margin-top: 16px;
  display: inline-block;
  vertical-align: top;
}

.header .middle-header.lesson-step .lesson-navigation {
  font-size: 0px;
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-top: 16px;
}

.header .middle-header.lesson-step .lesson-navigation a {
  background: #DEDEDE;
  border-radius: 50%;
  color: #9f9f9f;
  display: inline-block;
  font-size: 25px;
  text-align: center;
  margin-right: 10px;
  line-height: 36px;
  overflow: hidden;
  height: 38px;
  width: 38px;
  opacity: 0.5;
  margin-top: 4px;
  vertical-align: top;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.header .middle-header.lesson-step .lesson-navigation a:hover {
  opacity: 0.8;
}

.header .middle-header.lesson-step .lesson-navigation a.completed {
  background: #01D536;
  color: #fff;
}

.header .middle-header.lesson-step .lesson-navigation a.right {
  background: #01D536;
  color: #fff;
}

.header .middle-header.lesson-step .lesson-navigation a.completed {
  background: #01D536;
  color: #fff;
}

.header .middle-header.lesson-step .lesson-navigation a.onCheck {
  background: #d56b09;
  color: #fff;
}

.header .middle-header.lesson-step .lesson-navigation a.current-onCheck {
  background: #d56b09;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.header .middle-header.lesson-step .lesson-navigation a.unRight {
  background: #e52528;
  color: #fff;
}

.header .middle-header.lesson-step .lesson-navigation a.current-unRight {
  background: #e52528;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.header .middle-header.lesson-step .lesson-navigation a.current-completed {
  background: #01D536;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.header .middle-header.lesson-step .lesson-navigation a.current-right {
  background: #01D536;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.header .middle-header.lesson-step .lesson-navigation a.current-completed {
  background: #01D536;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.header .middle-header.lesson-step .lesson-navigation a.current {
  background: #679FFF;
  color: #fff;
  font-size: 34px;
  height: 46px;
  line-height: 45px;
  width: 46px;
  opacity: 1;
  margin-top: 0px;
}

.header .middle-header.all-courses {
  padding-bottom: 40px;
}

.header .middle-header.all-courses .page-title-wrap .page-title {
  font-size: 44px;
}

.change-language-selector {
  float: left;
  margin-top: 5px;
  margin-right: 7px;
}

#selectStudent,
.info_text {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.info {
  display: flex;
  flex-direction: column;
}

.info_select-wrapper,
.info_mentor{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .info {
    flex-direction: row;
  }

  .info_select-wrapper,
  .info_mentor {
    flex-direction: column;
  }
}
