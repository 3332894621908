.report-list {
  margin-top: 20px ;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 20px;
  position: relative;
}

.report-not-found {
  display: flex;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.report-message {
  background: #e5ecf7;
  padding: 15px 30px;
  border-radius: 5px;
}

.report-empty {
  min-height: 210px;
  min-width: 236px;
  max-width: 236px;
  background: #F6F6F6;
  box-sizing: border-box;
  padding: 25px;
}

.report-grid {
  display: grid;
  gap: 20px;
  overflow: hidden;
}

.report-row {
  display: flex;
  gap: 20px;
  overflow-x: scroll;
}
