.datepickerContainer {
  max-width: 600px;
  max-height: 600px;
  background-color: #f6f6f6;
  padding: 12px;
}

.datepickerContainerTitle {
  font-size: 1.2em;
  color: black;
  text-align: center;
}

.weekDayContainer {
  display: flex;
}

.weekDayCustom {
  display: block;
  flex: 1 0 0%;
  padding: 0.8em;
  background: transparent;
  font-size: 1.2em;
  color: black;
  text-align: center;
  width: calc(100% / 7);
}

.weekCustom {
  display: flex;
}

.emptyStateDay {
  font-size: 1.2em;
  display: block;
  flex: 1 0 0;
  width: calc(100% / 7);
  text-align: center;
  margin: 4px;
  padding: 16px;
  color: #adadad;
}

.emptyStateDay + .emptyStateDay,
.emptyStateDay + .day {
  margin-left: -1px;
}

.dayCustom {
  display: block;
  flex: 1 0 0%;
  width: 14.28571%;
  height: 14.28571%;
  margin: 4px;
  padding: 18px;
  border-radius: 50%;
  outline: none;
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;
}

.dayCustomBeforeCurrentDay {
  display: block;
  flex: 1 0 0%;
  width: 14.28571%;
  height: 14.28571%;
  margin: 4px;
  padding: 18px;
  border-radius: 50%;
  outline: none;
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;
  color: #adadad;
}

.dayCustomRegisterCustomDatePicker {
  display: block;
  flex: 1 0 0%;
  width: 14.28571%;
  height: 14.28571%;
  margin: 4px;
  padding: 18px;
  border-radius: 50%;
  outline: none;
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;
  background-color: #59da7a;
  text-decoration: underline;
}

.dayCustomCurrentCustomDatePicker {
  display: block;
  flex: 1 0 0%;
  width: 14.28571%;
  height: 14.28571%;
  margin: 4px;
  padding: 18px;
  border-radius: 50%;
  outline: none;
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;
  background-color: rgba(188, 188, 188, 0.5);
  text-decoration: underline;
}

.inputTimeCustomDatePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  font-size: 18px;
  line-height: 21px;
  color: #4ea3ff;
  border: solid 1px #4ea3ff;
  border-radius: 10px;
  height: 50px;
  width: 420px;
}

.disabledInputTimeCustomDatePicker {
  margin: 10px auto;
  padding: 12px;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  color: #c4c4c4;
  border: solid 1px #c4c4c4;
  border-radius: 10px;
  height: 50px;
  width: 420px;
}

.registeredInputTimeCustomDatePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  font-size: 18px;
  line-height: 21px;
  color: white;
  border: solid 1px #4ea3ff;
  background-color: #4ea3ff;
  border-radius: 10px;
  height: 50px;
  width: 420px;
}

.registeredInputTimeCustomDatePicker:hover {
  cursor: pointer;
}

.btnModalRegisterCustomDatePicker {
  background: #59da7a;
  border: 1px solid #59da7a;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  max-width: 200px;
  width: 100%;
  height: 50px;
  margin: 8px;
}

.btnModalBackCustomDatePicker {
  background-image: linear-gradient(-48deg, #4b8aff 0%, #407ef2 100%);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  max-width: 200px;
  width: 100%;
  height: 50px;
  margin: 8px;
}

.btnModalRefuseCustomDatePicker {
  background-color: #ffac30;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  width: 420px;
  height: 50px;
  border: none;
  justify-content: center;
}

.textCustomDatePicker {
  padding-top: 20px;
  text-align: center;
  align-items: center;
  font-size: large;
  background: #f6f6f6;
  color: black;
  padding-right: 30px;
  padding-left: 30px;
}

.modalBlockCustomDatePicker {
  position: relative;
  height: 500px;
  text-align: center;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalBlockCustomDatePicker {
  width: 100%;
}

.inputTimeCustomDatePicker {
  margin: 10px auto;
}

.inputTimeCustomDatePicker:hover {
  cursor: pointer;
}
