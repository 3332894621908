.course-progress-bar {
  position: relative;
  overflow: visible !important;

  &__spinner {
    position: absolute;
    width: 52px;
    right: -18px;
    top: -18px;
    background-color: #fff;
  }
}

.disabled-links a {
  cursor: not-allowed !important;
  pointer-events: none !important;
  background-color: #afafaf !important;
}
